<template>
    <div id="UserManagement">

        <div class="row">
            <div class="col-12">
                <h4>User Management</h4>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mt-2">
                <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button class="nav-link active" id="nav-editor-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-editor" type="button" role="tab" aria-controls="nav-editor"
                            aria-selected="true">Editors</button>
                        <button class="nav-link" id="nav-client-tab" data-bs-toggle="tab" data-bs-target="#nav-client"
                            type="button" role="tab" aria-controls="nav-client" aria-selected="false">Clients</button>
                    </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-editor" role="tabpanel" aria-labelledby="nav-home-tab">
                        <!-- Show all Editor users here-->
                        <div class="row mt-4 mb-4">
                            <div class="col-12">
                                <button class="btn btn-primary btn-sm" type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#createEditor" aria-controls="createEditor">Create New Editor</button>
                            </div>
                        </div>

                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Email</th>
                                    <th scope="col">Status</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user in editorUsers" :key="user.id">
                                    <td>{{ user.email }}</td>
                                    <td>{{ user.status }}</td>
                                    <td>
                                        <div class="btn-group" v-if="user.email != 'admin@localhost'">
                                            <button class="btn btn-primary btn-sm me-2">Edit</button>
                                            <button class="btn btn-secondary btn-sm me-2">Lock Account</button>
                                            <button class="btn btn-danger btn-sm me-2" v-on:click="deleteUser(user.id)" >Delete</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="tab-pane fade" id="nav-client" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <!-- Show all Client users here-->
                        <div class="row mt-4 mb-4">
                            <div class="col-12">
                                <button class="btn btn-primary btn-sm" type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#createClient" aria-controls="createClient">Create New Client</button>
                            </div>
                        </div>

                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Email</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Status</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user in clientUsers" :key="user.id">
                                    <td>{{ user.email }}</td>
                                    <td>{{ user.company }}</td>
                                    <td>{{ user.status }}</td>
                                    <td>
                                        <button class="btn btn-secondary btn-sm">Make an Editor</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <CreateEditor></CreateEditor>
    <CreateClient></CreateClient>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import CreateEditor from "./createEditor.vue";
import CreateClient from "./createClient.vue";

export default {
    name: "UserManagement",
    components: {
        CreateEditor,
        CreateClient,
    },
    data() {
        return {

        }
    },
    setup() {

        let clientUsers = ref();
        let editorUsers = ref();

        return {
            clientUsers,
            editorUsers,
        }
    },
    async created() {
        await this.start();
    },
    mounted() {

    },
    computed: {

    },
    watch: {

    },
    methods: {
        start: async function () {
            const editorsApiEndpoint = "/api/user/getalleditors/";
            const clientsApiEndpoint = "/api/user/getallclients/";

            const editorResponse = await axios({
                url: editorsApiEndpoint,
                method: 'GET',
            });

            this.editorUsers = editorResponse.data;

            const clientResponse = await axios({
                url: clientsApiEndpoint,
                method: 'GET',
            });

            this.clientUsers = clientResponse.data;
        },
        deleteUser: async function (id) {
            const apiEndpoint = "/api/user/deleteuser/?id=" + id;

            const response = await axios({
                url: apiEndpoint,
                method: 'DELETE',
            });

            await this.start();
        },
    },
};

</script>